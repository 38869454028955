import JSCookies from 'js-cookie';

import isBrowser from '../isBrowser';
import type { Lucid } from '../../types/types';

const get: Lucid.Utils.WithCookie.GetCookie = name => {
  if (name === 'username') {
    if (isBrowser()) return window.atob(JSCookies.get('username') || '');

    return undefined;
  }

  return JSCookies.get(name) || undefined;
};

const set: Lucid.Utils.WithCookie.SetCookie = (name, value, options = {}) => JSCookies.set(name, value, options);

const remove: Lucid.Utils.WithCookie.RemoveCookie = (name, options = {}) => JSCookies.remove(name, options);

const withCookie: Lucid.Utils.WithCookie.Methods = {
  get,
  set,
  remove,
};

export default withCookie;
